<template>
  <ImageContainer background class="page-header" :fileName="image">
    <div class="container">
      <ul class="breadcrumbs">
        <li
          v-for="(a, i) of breadcrumbs"
          :key="i"
          :class="{ active: i === breadcrumbs.length - 1 }"
        >
          <h3 class="fw-regular">
            {{ a }}
          </h3>
        </li>
      </ul>

      <h1 v-html="title" class="max-w-6" :class="titleClass" />
    </div>
  </ImageContainer>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    titleClass: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
  },
};
</script>
