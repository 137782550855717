<template>
  <PageHeader image="header-5" withMobile :title="$lang('title')" :breadcrumbs="$lang('breadcr')" />

  <section class="pt-8 pb-9 bg-blue4">
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-10">
          <h3 class="fw-semibold text-blue2">
            {{ $lang("section.title1") }}
          </h3>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 mb-4" v-for="(i, ix) of $lang('items')" :key="ix">
          <div class="card card-white m-0 h-100">
            <div class="card-header p-4">
              <ImageContainer
                background
                contain
                :fileName="i.logo"
                class="b-radius-0 mt-4 mb-5"
                size="70px"
                style="background-position: left top; min-height: var(--min-radius)"
              />
            </div>
            <div class="card-body min-h-3">
              <h3>{{ i.title }}</h3>
              <p>
                {{ i.body }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader,
  },
};
</script>

<style></style>
